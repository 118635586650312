import React, { useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "./../components/Search";
import CreateIcon from "../components/Create";
import { reqGetListCustomers } from "../../../reduxs/cms/action";
import EditCustomerModal from "./EditCustomerModal";
import "./index.scss";
import AddCustomerModal from "./AddCustomerModal";
import { toast } from "react-toastify";
import CustomerFavoritesModal from "./CustomerFavoritesModal";
import customersAPI from '../../../apis/api/customer';
import { getSafeCountryCallingCode } from "../../../components/phone-input/phone-input";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

export const PurchaseOptions = [
  { label: "Primary Residence", value: "Primary Residence" },
]

const Row = ({ data, onEditCustomer, onViewFavorite, onSaveSuccess }) => {
  const {t} = useTranslation();
  const [show, setShow] = useState(false);

  const handleCopyURL = () => {
    if (!data) return;
    const url = data?.uniqueUrl || `${process.env.REACT_APP_URL}/${data?.id}`;
    navigator.clipboard.writeText(url);
    toast.success('Copied customer session url!');
  }

  const handleUpdateActive = async (e) => {
    if (!data) return;
		
		try {
			const updated = {
				isActive: e?.target?.checked || false,
			};
			const res = await customersAPI.updateCustomer(data.id, updated);
			if (res.data) {
				toast.success("Customer updated successfully!"); 
				onSaveSuccess && onSaveSuccess();
			}
		} catch (err) {
			toast.error(err.message);
		}
  }

  return (
		<div className={`content-row ${!show ? "collapsed": ""}`}>
      <div className="arrow">
				<img
					onClick={() => {setShow(!show)}}
					src={`/icons/arrow-${show? "up": "down"}.svg`}
					alt=""
				/>
			</div>
      <div>{data?.name}</div>
      <div>{data?.email}</div>
      <div>{data?.countryCode ? `+${getSafeCountryCallingCode(data?.countryCode)}`: ''} {data?.mobile}</div>
      <div>{show ? data?.address : '...'}</div>
      <div className="action-view" onClick={() => onViewFavorite?.(data)}>
        {t("VIEW")}
      </div>
      <div className="url">
        <div className="wrap-url">
          <span className="url-link">{data?.uniqueUrl || `${process.env.REACT_APP_URL}/${data?.id}`}</span>
          <span className="action-copy" onClick={() => handleCopyURL()}>{t("COPY")}</span>
        </div>
       
      </div>
      <div className="status">
        <div className="form-check form-switch my-n1">
          <input
            className="form-check-input"
            type="checkbox"
            id="customer-state-1"
            defaultChecked={data?.isActive}
            onChange={handleUpdateActive}
          />
          <label className="form-check-label" htmlFor="customer-state-1" />
        </div>
      </div>
      <div onClick={() => onEditCustomer?.(data)}>
        <div className="action-edit">
          <img className="img-fluid" src="/icons/edit-button.svg" alt="" />
        </div>
      </div>
    </div>
  );
}

const CMSCustomers = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [isShowEditCustomerModal, setIsShowEditCustomerModal] = useState(false);
  const [isShowAddCustomerModal, setIsShowAddCustomerModal] = useState(false);
  const [isShowCustomerFavoritesModal, setIsShowCustomerFavoritesModal] = useState(false);
  const [seletedCustomer, setSelectedCustomer] = useState(null);
  const [search, setSearch] = useState('');
  const [isSortAsc, toggleSortAsc] = useState(true);

  const customers = useSelector((state) => state.cms.customers);

  useEffect(() => {
    getListCustomers();
  }, [search, isSortAsc])

  useEffect(() => {
    getListCustomers();
  }, []);

  const getListCustomers = () => {
    dispatch(reqGetListCustomers({
      search,
      sortBy: JSON.stringify({
        name: isSortAsc ? 1 : -1,
      }),
    }));
  }

  const onSaveSuccess = () => {
    getListCustomers();
  }

  const onCreateSuccess = () => {
    getListCustomers();
  }

  const onEditCustomer = (customer) => {
    setSelectedCustomer(customer);
    setIsShowEditCustomerModal(true);
  }

  const onAddNewCustomer = () => {
    setIsShowAddCustomerModal(true);
  }

  const onViewFavorite = (customer) => {
    setSelectedCustomer(customer);
    setIsShowCustomerFavoritesModal(true);
  }

  const onSearch = (e) => {
    setSearch(e.target?.value);
  } 

  const renderListProperty = () => {
    return (customers || []).map((item, index) => {
      return (
        <Row
          key={item.id}
          data={item}
          index={index}
          onEditCustomer={onEditCustomer}
          onViewFavorite={onViewFavorite}
          onSaveSuccess={onSaveSuccess}
        />
      );
    });
  };

  const renderContent = () => {
    return (
      <div className="content">
				<div className="content-header">
          <div></div>
          <div>{t("Name")}</div>
          <div>{t("Email")}</div>
          <div>{t("Phone")}</div>
          <div>{t("ADDRESS")}</div>
          <div>{t("FAVORITES")}</div>
          <div>{t("UNIQUE URL")}</div>
          <div>{t("ACTIVE")}</div>
          <div></div>
				</div>
				<div className="content-list">
          {renderListProperty()}
        </div>
			</div>
    );
  };

  return (
    <div className="page-container">
      <div className="page-header d-flex align-items-center">
        <h1 className="f-title mb-0">{t("CUSTOMERS CMS")}</h1>
      </div>
      <div className="page-body customers-page-body">
        {renderContent()}
      </div>
      { isShowEditCustomerModal &&
        <EditCustomerModal 
          show={isShowEditCustomerModal}
          setShow={setIsShowEditCustomerModal}
          customer={seletedCustomer}
          onSaveSuccess={onSaveSuccess}
        />
      }
      {
        isShowAddCustomerModal &&
        <AddCustomerModal
          show={isShowAddCustomerModal}
          setShow={setIsShowAddCustomerModal}
          onCreateSuccess={onCreateSuccess}
        />
      }
      {
        isShowCustomerFavoritesModal && 
        <CustomerFavoritesModal
          show={isShowCustomerFavoritesModal}
          setShow={setIsShowCustomerFavoritesModal}
          customer={seletedCustomer}
        />
      }
      <div onClick={onAddNewCustomer}>
        <CreateIcon />
      </div>
      <SearchIcon handleInputChange={onSearch}/>
    </div>
  );
};
export default CMSCustomers;
