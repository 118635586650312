import './index.scss';
import { createPortal } from 'react-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.min.css'

import { getUploadedServerMediaUrl } from "../../../helper/media";
import React, { useEffect, useState } from "react";
import { Navigation } from "swiper";
import socket from '../../../helper/socket';
import { ACTION_NAME, WEBSOCKET_CHANNEL } from '../../../constants/options';

const PopupGallery = ({ show, listImage, onClose, isPresentation }) => {
  const [swiper, setSwiper] = useState(null)

  useEffect(() => {
    if (isPresentation && swiper) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CHANGE_SLIDE_GALLERY) {
          handleIndexChange(content.data.index)
        }
      })
    }
  }, [isPresentation, swiper])

  const handleIndexChange = (index) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CHANGE_SLIDE_GALLERY, {
        index
      })
    } else {
      if (swiper && swiper?.params) swiper.slideToLoop(index, 150);
    }
  }

  const handleNavigation = (direction) => {
    if (direction === 'left') {
      swiper.slidePrev();
    } else {
      swiper.slideNext();
    }
  }

  return <>
    {show && createPortal(
      <div className={'media-popup-gallery'}>
        <div className={'popup-gallery-wrapper'}>
          <div className={'popup-gallery-close'} onClick={onClose}>
            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L25 22.8182M25 1L1 22.8182" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

          </div>
          <Swiper
            onSwiper={(swiper) => setSwiper(swiper)}
            navigation={true}
            modules={[Navigation]}
            className={'popup-gallery-swiper'}
            initialSlide={listImage.findIndex((item) => item.path === show)}
            loop={true}
            onRealIndexChange={s => handleIndexChange(s.realIndex)}
          >
            {
              listImage && listImage.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div key={'item-' + index} className="container-image">
                      <img style={{ width: '100%', height: '100%' }} alt={item?.name}
                        src={getUploadedServerMediaUrl(item?.path)}
                        width={'100%'}
                        height={'100%'}
                      />
                    </div>
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
          <div className={'popup-gallery-navigation left'} onClick={() => handleNavigation('left')}>
            <svg width="26" height="52" viewBox="0 0 26 52" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M25.9009 48.5947C25.8355 48.7525 25.7396 48.8959 25.6188 49.0166C25.498 49.1375 25.3547 49.2334 25.1969 49.2988C25.0391 49.3642 24.8699 49.3979 24.6991 49.3979C24.5283 49.3979 24.3591 49.3642 24.2013 49.2988C24.0435 49.2334 23.9001 49.1375 23.7794 49.0166L0.381242 25.6185C0.260382 25.4977 0.164504 25.3544 0.0990876 25.1966C0.0336711 25.0388 0 24.8696 0 24.6988C0 24.528 0.0336711 24.3588 0.0990876 24.201C0.164504 24.0432 0.260382 23.8998 0.381242 23.7791L23.7794 0.380942C23.9002 0.260168 24.0436 0.164366 24.2014 0.0990033C24.3592 0.0336409 24.5283 0 24.6991 0C24.8699 0 25.039 0.0336409 25.1968 0.0990033C25.3546 0.164366 25.498 0.260168 25.6188 0.380942C25.7395 0.501716 25.8353 0.645096 25.9007 0.802894C25.9661 0.960693 25.9997 1.12982 25.9997 1.30062C25.9997 1.47142 25.9661 1.64055 25.9007 1.79835C25.8353 1.95615 25.7395 2.09952 25.6188 2.2203L4.44017 23.3989L3.85877 24.0485L3.27737 24.6981L3.85877 25.3484L4.44017 25.9987L25.6188 47.1773C25.7396 47.298 25.8355 47.4413 25.9009 47.5992C25.9663 47.757 26 47.9261 26 48.0969C26 48.2678 25.9663 48.4369 25.9009 48.5947Z" fill="white" />
            </svg>
          </div>
          <div className={'popup-gallery-navigation right'} onClick={() => handleNavigation('right')}>
            <svg width="26" height="52" viewBox="0 0 26 52" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M0.0990868 48.5947C0.164503 48.7525 0.260384 48.8959 0.381243 49.0166C0.501968 49.1375 0.64533 49.2334 0.803135 49.2988C0.960939 49.3642 1.13009 49.3979 1.30092 49.3979C1.47175 49.3979 1.6409 49.3642 1.7987 49.2988C1.95651 49.2334 2.09987 49.1375 2.2206 49.0166L25.6188 25.6185C25.7396 25.4977 25.8355 25.3544 25.9009 25.1966C25.9663 25.0388 26 24.8696 26 24.6988C26 24.528 25.9663 24.3588 25.9009 24.201C25.8355 24.0432 25.7396 23.8998 25.6188 23.7791L2.2206 0.380942C2.09983 0.260168 1.95645 0.164366 1.79865 0.0990033C1.64085 0.0336409 1.47172 0 1.30092 0C1.13012 0 0.960995 0.0336409 0.803196 0.0990033C0.645397 0.164366 0.502016 0.260168 0.381243 0.380942C0.260469 0.501716 0.164667 0.645096 0.0993061 0.802894C0.0339432 0.960693 0.000299454 1.12982 0.000299454 1.30062C0.000299454 1.47142 0.0339432 1.64055 0.0993061 1.79835C0.164667 1.95615 0.260469 2.09952 0.381243 2.2203L21.5598 23.3989L22.1412 24.0485L22.7226 24.6981L22.1412 25.3484L21.5598 25.9987L0.381243 47.1773C0.260384 47.298 0.164503 47.4413 0.0990868 47.5992C0.0336704 47.757 0 47.9261 0 48.0969C0 48.2678 0.0336704 48.4369 0.0990868 48.5947Z" fill="white" />
            </svg>
          </div>
        </div>


      </div>,
      document.body
    )}

  </>
}
export default PopupGallery
