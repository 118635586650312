import React, { useEffect, useMemo, useRef, useState } from "react";
import UnitList from "./UnitList";
import UnitDetail from "./UnitDetail";
import UnitFilter from "./UnitFilter";
import "./index.scss";
import FloorPlanGallery from "../floor-plan-gallery";
import Gallery from "../gallery";
import VirtualTour from "../virtual-tour";
import { useSelector } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useDispatch } from "react-redux";
import * as unitExploreAct from "../../reduxs/unit-explore/action";

const UnitExplore = (props) => {
  const { handleUnitClick, setActiveObjectIds, isPresentation } = props;
  const dispatch = useDispatch();
  const floorPlanGalleryRef = useRef();
  const [unitListScroll, setUnitListScroll] = useState(null);

  const isShowFloorplan = useSelector(
    (state) => state.unitExplore.isShowFloorplan
  );
  const isShowGallery = useSelector((state) => state.unitExplore.isShowGallery);
  const isShowVirtualTour = useSelector(
    (state) => state.unitExplore.isShowVirtualTour
  );
  const isShowUnitDetail = useSelector(
    (state) => state.unitExplore.isShowUnitDetail
  );
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const isShowFilter = useSelector((state) => state.unitExplore.isShowFilter);
  const isTransparent = useSelector((state) => state.home.isTransparent);
  const units = useSelector((state) => state.unitExplore.units);
  const filteredUnits = useSelector((state) => state.unitExplore.filteredUnits);

  const filterUnitBedroom = useSelector(
    (state) => state.unitExplore.filterUnitBedroom
  );
  const filterUnitAspect = useSelector(
    (state) => state.unitExplore.filterUnitAspect
  );
  const filterUnitRoomType = useSelector(
    (state) => state.unitExplore.filterUnitRoomType
  );
  const filterUnitPrice = useSelector(
    (state) => state.unitExplore.filterUnitPrice
  );
  const filterUnitLotSize = useSelector(
    (state) => state.unitExplore.filterUnitLotSize
  );
  const filterUnitAvailability = useSelector(
    (state) => state.unitExplore.filterUnitAvailability
  );
  const unitQuery = useSelector((state) => state.unitExplore.unitQuery);
  const isShowUnitList = useSelector(
    (state) => state.unitExplore.isShowUnitList
  );

  const isFiltered = useMemo(() => {
    return (
      filterUnitAspect ||
      filterUnitBedroom.length ||
      filterUnitPrice?.max ||
      filterUnitPrice?.min ||
      filterUnitRoomType.length ||
      filterUnitLotSize?.max ||
      filterUnitLotSize?.min ||
      filterUnitAvailability.length
    );
  }, [filterUnitAspect, filterUnitBedroom, filterUnitPrice, filterUnitRoomType, filterUnitLotSize, filterUnitAvailability])

  useEffect(() => {
    if (isFiltered && !selectedUnit) {
      setActiveObjectIds(
        filteredUnits.map((item) => {
          return item["3d_asset"]?.id;
        })
      );
    } else if (selectedUnit) {
      const unitFiltereds = units.filter((item) => item.id == selectedUnit.id);
      if (unitFiltereds.length)
        setActiveObjectIds([unitFiltereds[0]["3d_asset"]?.id]);
    } else {
      setActiveObjectIds([]);
    }
  }, [filteredUnits, isFiltered, selectedUnit]);

  const handleGetUnit = () => {
    dispatch(unitExploreAct.reqGetUnitList(unitQuery));
  }

  const handleFilterUnit = () => {
    if (!units && !units.length) return;
    let filterUnits = [...units];
    filterUnits = filterUnits.filter((unit) => {
      if (filterUnitAspect && unit.aspect != filterUnitAspect.value) return false

      if (filterUnitBedroom.length) {
        const bedroomValue = filterUnitBedroom.map(x => x.value)
        if (!bedroomValue.includes(unit.bedrooms)) return false
      }

      if (filterUnitAvailability.length) {
        const availabilityValue = filterUnitAvailability.map(x => x.value)
        if (!availabilityValue.includes(unit.availabilityStatus)) return false
      }

      if (filterUnitPrice) {
        if (filterUnitPrice.min && unit.price < filterUnitPrice.min) return false
        if (filterUnitPrice.max && unit.price > filterUnitPrice.max) return false
      }

      if (filterUnitLotSize) {
        if (filterUnitLotSize.min && unit.totalLot < filterUnitLotSize.min) return false
        if (filterUnitLotSize.max && unit.totalLot > filterUnitLotSize.max) return false
      }

      return unit
    })
    dispatch(unitExploreAct.reqSetFilteredUnit(filterUnits))
  };

  useEffect(() => {
    handleGetUnit()
  }, [])

  useEffect(() => {
    handleFilterUnit();
  }, [
    filterUnitAspect,
    filterUnitBedroom,
    filterUnitPrice,
    filterUnitLotSize,
    filterUnitAvailability,
    units
  ]);

  return (
    <>
      <div className={`wrap-sidenav`}>
        <CSSTransition
          in={isShowFilter}
          timeout={500}
          classNames="fade-left"
          unmountOnExit
        >
          <UnitFilter isPresentation={isPresentation} isTransparent={isTransparent} />
        </CSSTransition>
        <div
          className={`float-start position-relative`}
        >
          <CSSTransition
            in={!isTransparent && isShowUnitList}
            timeout={500}
            classNames="fade-left"
            unmountOnExit
          >
            <UnitList
              isPresentation={isPresentation}
              handleUnitClick={handleUnitClick}
              unitListScroll={unitListScroll}
              setUnitListScroll={setUnitListScroll}
            />
          </CSSTransition>
        </div>
      </div>
      <CSSTransition
        in={!!selectedUnit && isShowUnitDetail}
        timeout={500}
        classNames="fade-item"
        unmountOnExit
      >
        <div
          className={`wrap-sidenav wrap-sidenav--right ${isTransparent ? "invisible" : ""
            }`}
        >
          <UnitDetail isPresentation={isPresentation} />
        </div>
      </CSSTransition>

      <CSSTransition
        in={isShowFloorplan}
        timeout={1000}
        classNames="fade-item"
        unmountOnExit
      >
        <FloorPlanGallery isPresentation={isPresentation} floorPlanGalleryRef={floorPlanGalleryRef} />
      </CSSTransition>

      <TransitionGroup>
        {isShowGallery && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <Gallery isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>

      <TransitionGroup>
        {isShowVirtualTour && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <VirtualTour isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );
};

export default UnitExplore;
