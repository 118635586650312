import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  BEDROOM_OPTION,
  ASPECT_OPTION,
  AVAILABILITY_OPTIONS,
  WEBSOCKET_CHANNEL,
  ACTION_NAME
} from "../../constants/options";
import SideNav from "../sidenav";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import FilterGroupRange from "../filter-group-range";
import classNames from "classnames";
import { debounce } from 'lodash'
import socket from "../../helper/socket";

const UnitFilter = (props) => {
  const { isPresentation, isTransparent } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const refFilterRangePrice = useRef();
  const refFilterRangeLot = useRef();
  const ref = useRef([]);
  const pageRef = useRef(null);

  useEffect(() => {
    document.addEventListener("UNIT_EXPLORER_PAGE", function (e) {
      onResetAllFilter();
    });
  }, []);
  const isShowUnitList = useSelector(
    (state) => state.unitExplore.isShowUnitList
  );
  const isShowFilter = useSelector((state) => state.unitExplore.isShowFilter);
  const filterUnitBedroom = useSelector(
    (state) => state.unitExplore.filterUnitBedroom
  );
  const filterUnitAspect = useSelector(
    (state) => state.unitExplore.filterUnitAspect
  );
  const filterUnitPrice = useSelector(
    (state) => state.unitExplore.filterUnitPrice
  );
  const filterUnitLotSize = useSelector(
    (state) => state.unitExplore.filterUnitLotSize
  );
  const filterUnitAvailability = useSelector(
    (state) => state.unitExplore.filterUnitAvailability
  );

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CLICK_UNIT_FILTER) {
          handleApplyFilter(content.data);
        } else if (content.action == ACTION_NAME.CLEAR_ALL_FILTERS) {
          onResetAllFilter();
        } else if (content.action == ACTION_NAME.HIDE_FILTER) {
          onHideFilter();
        }

        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          if (pageRef.current && content.data) {
            pageRef.current.scrollTop =
              content.data.scrollTop * pageRef.current.scrollHeight
          }
        }
      });
    }
  }, [isPresentation]);

  const handleApplyFilter = debounce((data) => {
    const filterName = data.name;
    const item = data?.item;

    switch (filterName) {
      case "FILTER_BEDROOM":
        onChangeBedroom(item, data.filterUnitBedroom);
        break;
      case "FILTER_ASPECT":
        onFilterUnitAspect(item, data.filterUnitAspect);
        break;
      case "FILTER_PRICE":
        onChangePrice(data.price);
        break;
      case "FILTER_LOT_SIZE":
        onChangeLotSize(data.filterLotSize);
        break;
      case "FILTER_AVAILABILITY":
        onChangeAvailability(data.checked, item, data.filterUnitAvailability);
        break;
      default:
        break;
    }
  }, 500);

  const unChecked = () => {
    if (!ref.current) return;

    for (let i = 0; i < ref.current.length; i++) {
      if (ref.current[i]) {
        ref.current[i].checked = false;
      }
    }
  };

  const onChangeBedroom = (item, filterUnitBedroom) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        item: item,
        filterUnitBedroom,
        name: "FILTER_BEDROOM",
      });
    }
    if (_.find(filterUnitBedroom, (el) => el.id === item.id)) {
      const newBedRoom = filterUnitBedroom.filter((i) => i.id !== item.id);
      dispatch(unitExploreAct.reqFilterUnitBedroom(newBedRoom));
    } else {
      dispatch(
        unitExploreAct.reqFilterUnitBedroom([...filterUnitBedroom, item])
      );
    }
    dispatch(unitExploreAct.reqSetIsShowUnitList(true));
  };

  const onChangeRoomType = (e, item, filterUnitRoomType) => {
    if (e.target.checked) {
      dispatch(
        unitExploreAct.reqFilterUnitRoomType([...filterUnitRoomType, item])
      );
    } else {
      dispatch(
        unitExploreAct.reqFilterUnitRoomType(
          filterUnitRoomType.filter((i) => i.id !== item.id)
        )
      );
    }
  };

  const onFilterUnitAspect = (item, filterUnitAspect) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        item: item,
        filterUnitAspect,
        name: "FILTER_ASPECT",
      });
    }
    const unitAspect = filterUnitAspect?.id == item.id ? "" : item;
    dispatch(unitExploreAct.reqFilterUnitAspect(unitAspect));
    dispatch(unitExploreAct.reqSetIsShowUnitList(true));
  };

  const onChangePrice = (data) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        price: data,
        name: "FILTER_PRICE",
      });
    }
    dispatch(unitExploreAct.reqFilterUnitPrice(data));
    if (!isShowUnitList) {
      dispatch(unitExploreAct.reqSetIsShowUnitList(true));
    }
  };

  const onChangeLotSize = (data) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        filterLotSize: data,
        name: "FILTER_LOT_SIZE",
      });
    }
    dispatch(unitExploreAct.reqFilterUnitLotSize(data));
    if (!isShowUnitList) {
      dispatch(unitExploreAct.reqSetIsShowUnitList(true));
    }
  };

  const onChangeAvailability = (checked, item, filterUnitAvailability) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        item: item,
        filterUnitAvailability,
        checked,
        name: "FILTER_AVAILABILITY",
      });
    }
    if (checked) {
      dispatch(
        unitExploreAct.reqFilterUnitAvailability([
          ...filterUnitAvailability,
          item
        ])
      );
    } else {
      dispatch(
        unitExploreAct.reqFilterUnitAvailability(
          filterUnitAvailability.filter((i) => i.id !== item.id)
        )
      );
    }
    dispatch(unitExploreAct.reqSetIsShowUnitList(true));
  };

  const onResetAllFilter = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLEAR_ALL_FILTERS);
    }
    unChecked();
    dispatch(unitExploreAct.reqFilterUnitBedroom([]));
    dispatch(unitExploreAct.reqFilterUnitAvailability([]));
    dispatch(unitExploreAct.reqFilterUnitAspect(""));
    dispatch(unitExploreAct.reqSetIsShowUnitList(false));
    dispatch(unitExploreAct.reqSetSelectedUnit(""));
    if (refFilterRangePrice.current) {
      refFilterRangePrice?.current?.reset &&
        refFilterRangePrice.current.reset();
      dispatch(unitExploreAct.reqFilterUnitPrice(""));
    }
    if (refFilterRangeLot.current) {
      refFilterRangeLot?.current?.reset && refFilterRangeLot.current.reset();
      dispatch(unitExploreAct.reqFilterUnitLotSize(""));
    }
  };

  const onHideFilter = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.HIDE_FILTER);
    }
    dispatch(unitExploreAct.reqSetIsShowFilter(false));
  }

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop:
          event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  };

  return (
    <SideNav
      position="left"
      heading={t("UnitFilter.header")}
      id="filter"
      isDark={false}
      isTransparent={isTransparent}
    >
      <div ref={pageRef} className="scroll-filter disable-scroll-bar" onScroll={onScroll}>
        {/* filter avaibility */}
        <div className="filter-group" >
          <h2 className="heading">{t("UnitFilter.AVAILABILITY")}</h2>
          <ul className="list-group flex flex-column justify-content-between flex-wrap">
            {AVAILABILITY_OPTIONS.map((item, key) => (
              <li
                className={`list-group-item border-none`}
                key={`filter-item-${key}`}
              >
                <div className="pretty p-svg p-plain">
                  <input
                    ref={(element) => {
                      ref.current[key] = element;
                    }}
                    onChange={(e) =>
                      onChangeAvailability(e.target.checked, item, filterUnitAvailability)
                    }
                    type="checkbox"
                    checked={filterUnitAvailability
                      .map((avaibility) => avaibility.value)
                      .includes(item.value)}
                  />
                  <div className="state">
                    <img className="svg" src="icons/task-light.svg" />
                    <label>{t(`AVAILABILITY_OPTIONS.${item.title}`)}</label>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* filter celling height */}

        <FilterGroupRange
          ref={refFilterRangePrice}
          label={{
            title: t("UnitFilter.PRICE_RANGE"),
            min: t("UnitFilter.min_price"),
            max: t("UnitFilter.max_price")
          }}
          filterValue={{
            min: filterUnitPrice?.min,
            max: filterUnitPrice?.max
          }}
          max={5000000}
          onChange={onChangePrice}
        />

        {/* filter celling height */}
        <FilterGroupRange
          ref={refFilterRangeLot}
          label={{
            title: t("UnitFilter.LOT_SIZE_RANGE"),
            min: t("UnitFilter.filterUnitLotSize1"),
            max: t("UnitFilter.filterUnitLotSize2")
          }}
          max={400}
          icon={"----"}
          filterValue={{
            min: filterUnitLotSize?.min,
            max: filterUnitLotSize?.max
          }}
          onChange={onChangeLotSize}
        />

        {/* filter bedrooms  */}
        <div className="filter-group">
          <h2 className="heading">{t("UnitFilter.BEDROOMS")}</h2>
          <ul className="list-group flex flex-row justify-content-between flex-wrap">
            {BEDROOM_OPTION.map((item, key) => (
              <li
                onClick={() => onChangeBedroom(item, filterUnitBedroom)}
                className={`list-group-number-item rounded-0 ${_.find(filterUnitBedroom, (i) => i.id == item.id)
                    ? "active"
                    : ""
                  }`}
                key={`filter-item-${key}`}
              >
                {item.title}
              </li>
            ))}
          </ul>
        </div>

        {/* filter aspect */}
        <div className="filter-group aspect">
          <h2 className="heading">{t("UnitFilter.ASPECT")}</h2>
          <ul className="list-group flex flex-row">
            {ASPECT_OPTION.map((item, key) => (
              <li
                onClick={() => onFilterUnitAspect(item, filterUnitAspect)}
                className={`rounded-0 border-1 mb-1 list-group-item-btn ${item.id === filterUnitAspect?.id ? "active" : ""
                  }`}
                key={`filter-item-${key}`}
              >
                <div className="d-flex flex-column">
                  <small className="name">{t(item.name)}</small>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="btn-bottom">
          <span
            onClick={() => {
              onResetAllFilter();
            }}
            className={classNames(
              "text-center mt-1",
              (isShowUnitList && isShowFilter) || (filterUnitAspect !== "" || filterUnitBedroom.length || filterUnitPrice !== "" || filterUnitLotSize !== "" || filterUnitAvailability.length) ? "" : "disable"
            )}
          >
            {t("UnitFilter.CLEAR_FILTERS")}
          </span>
          <span
            onClick={onHideFilter}
            className="text-center mt-1"
          >
            {t("UnitFilter.HIDE_FILTERS")}
          </span>
        </div>
      </div>
    </SideNav>
  );
};

export default UnitFilter;
