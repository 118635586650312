import { Modal } from "react-bootstrap";
import closeIcon from "../../../assets/images/close-white.svg";
import React, { useEffect, useState } from "react";
import { parseResidenceData, validationResidenceSchema } from "./utils";
import { useTranslation } from "react-i18next";
import CurrencyInput from "react-currency-input-field";

const UpdateResidenceModal = ({show, data, onSave, onHide}) => {
  const {t} = useTranslation();
  const [formData, setFormData] = useState();
  const [isDirty, setIsDirty] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if(show && data) {
      setFormData(parseResidenceData(data))
    }
  }, [show, data])

  const handleChange = (value, field) => {
    setFormData((currentData) => ({
      ...currentData,
      [field]: value
    }))
    if(!isDirty) {
      setIsDirty(true)
    }
  }

  const handleSubmit =  async () => {
    if(!isDirty) return handleClose()
    try {
      await validationResidenceSchema.validate(formData, { abortEarly: false })
      setErrors({})
      await onSave(formData)
      handleClose()
    } catch (error) {
      if(error.inner) {
        const errs = {}
        error.inner.forEach(err => {
          errs[err.path] = err.message
        })
        setErrors(errs)
      }
    }
  }

  const handleClose = () => {
    setFormData(undefined)
    setErrors({})
    setIsDirty(false)
    onHide()
  }

  return  (
    <>
      {show && <div className="close-modal-btn">
        <img src={closeIcon} alt="close-icon" onClick={handleClose} />
      </div>}
      <Modal
        className="wrap-detail-modal"
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
      >
        <Modal.Body className="wrap-modal-body">
          <div
            className="modal-form__title">
            <span className="title">{t("Update residence")}</span>
          </div>
          <div className="wrap-form">
            <div className="flex-row">
              <div className="form-info">
                <label htmlFor="name">{t("Residence Name")}*</label>
                <input
                  onChange={(e) => handleChange(e.target.value, "name")}
                  value={formData?.name}
                  id="name"
                  placeholder="Residence ####"
                />
                <span>{t(errors?.name)}</span>
              </div>
              <div className="form-info">
                <label htmlFor="level">{t("Residence Level")}*</label>
                <input
                  onChange={(e) => handleChange(e.target.value, "level")}
                  value={formData?.level}
                  id="level"
                  placeholder="##"
                />
                <span>{t(errors?.level)}</span>
              </div>
            </div>
            <div className="flex-row">
              <div className="form-info">
                <label htmlFor="availabilityStatus">{t("Availability")}*</label>
                <select
                  onChange={(e) => handleChange(e.target.value, "availabilityStatus")}
                  id="availabilityStatus"
                  value={formData?.availabilityStatus}
                  placeholder="Residence ####"
                >
                  <option value="available">{t("available")}</option>
                  <option value="reserved">{t("reserved")}</option>
                  <option value="sold">{t("sold")}</option>
                </select>
                <span>{t(errors?.availabilityStatus)}</span>
              </div>
              <div className="form-info">
                <label htmlFor="totalLot">{t("Lot Size")}*</label>
                <input
                  onChange={(e) => handleChange(e.target.value, "totalLot")}
                  value={formData?.totalLot}
                  id="totalLot"
                  placeholder="#####"
                />
                <span>{t(errors?.totalLot)}</span>
              </div>
            </div>
            <div className="flex-row">
              <div className="form-info">
                <label htmlFor="bedrooms">{t("Beds")}*</label>
                <input
                  onChange={(e) => handleChange(e.target.value, "bedrooms")}
                  value={formData?.bedrooms}
                  id="bedrooms"
                  placeholder="#"
                />
                <span>{t(errors?.bedrooms)}</span>
              </div>
              <div className="form-info">
                <label htmlFor="aspect">{t("Aspect")}*</label>
                <select
                  onChange={(e) => handleChange(e.target.value, "aspect")}
                  id="aspect"
                  value={formData?.aspect}
                  placeholder="Sea"
                >
                  <option value="city_view">{t("City View")}</option>
                  <option value="Sea View">{t("Sea View")}</option>
                </select>
                <span>{t(errors?.aspect)}</span>
              </div>
            </div>
            <div className="flex-row">
              <div className="form-info">
                <label htmlFor="price">{t("Price")}*</label>
                <CurrencyInput
                    name="price"
                    id="price"
                    value={formData?.price}
                    placeholder="$$$$$$"
                    onChange={(e) => {
                      e.preventDefault();
                      const { value = "" } = e.target;
                      let parsedValue = value.replace(/[^\d.]/gi, "");
                      const parts = parsedValue.split(".")
                      if(parts.length > 1) {
                        const decimalPart = parts[1].substring(0, 2)
                        parsedValue = [parts[0], decimalPart].join(".");
                      }
                      handleChange(parsedValue, "price")
                    }}
                    allowDecimals
                    decimalsLimit={2}
                    disableAbbreviations
                />
                <span>{t(errors?.price)}</span>
              </div>
              <div className="form-info">
                <label htmlFor="serviceCharge">{t("Service Charge")}*</label>
                <CurrencyInput
                  name="serviceCharge"
                  id="serviceCharge"
                  value={formData?.serviceCharge}
                  placeholder="$$$$$$"
                  onChange={(e) => {
                    e.preventDefault();
                      const { value = "" } = e.target;
                      let parsedValue = value.replace(/[^\d.]/gi, "");
                      const parts = parsedValue.split(".")
                      if(parts.length > 1) {
                        const decimalPart = parts[1].substring(0, 2)
                        parsedValue = [parts[0], decimalPart].join(".");
                      }
                      handleChange(parsedValue, "serviceCharge")
                  }}
                  allowDecimals
                  decimalsLimit={2}
                  disableAbbreviations
                />
                <span>{t(errors?.serviceCharge)}</span>
              </div>
            </div>
            <div className="flex-row">
              <div className="w-100 form-info">
                  <label htmlFor="description">{t("Description")}*</label>
                  <textarea
                  onChange={(e) => handleChange(e.target.value, "description")}
                  value={formData?.description}
                    id="description"
                    placeholder="Description"
                  />
                  <span>{t(errors?.description)}</span>
              </div>
            </div>
            <div className="form-info w-100">
              <button onClick={handleSubmit}>
                {t("SAVE")}
              </button>
            </div>
          </div>
        
        </Modal.Body>
      </Modal>
  </>
  )
}

export default UpdateResidenceModal;
