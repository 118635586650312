import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  reqSetIsShowCreateConsultantProfileModal,
  reqSetIsShowCreateInvestorProfileModal,
  reqSetIsShowCreateTenantProfileModal,
  reqSetIsShowListConsultantModal,
  reqSetIsShowListInvestorModal,
  reqSetIsShowListTenantModal,
  reqSetIsShowUserTypeModal,
} from "../../reduxs/guide-session/action";
import ErrorModal from "./error-modal";
import customerAPi from "../../apis/api/customer";

import closeIcon from "../../assets/images/close-white.svg";

import "./index.scss";

const ListUserSelectModal = (props) => {
  const { startDiscover } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [listUserSelect, setListUserSelect] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [emailAddress, setEmailAddress] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [isShowNotFound, setShowNotFound] = useState(false);
  const isShowListInvestorModal = useSelector(
    (state) => state.guideSession.isShowListInvestorModal
  );
  const isShowListTenantModal = useSelector(
    (state) => state.guideSession.isShowListTenantModal
  );
  const isShowListConsultantModal = useSelector(
    (state) => state.guideSession.isShowListConsultantModal
  );

  const userSelectType = useSelector(
    (state) => state.guideSession.userSelectType
  );

  const getListInvestor = async () => {
    const data = {
      firstname: firstName,
      surname: lastName,
      email: emailAddress,
    };

    const result = await customerAPi.findCustomer(data);
    if (result?.data) {
      onStartUserSession(result.data);
    } else {
      setShowNotFound(true);
    }
  };

  const getListConsultant = async () => {
    const data = {
      firstname: firstName,
      surname: lastName,
      email: emailAddress,
    };

    const result = await customerAPi.findCustomer(data);

    if (result?.data) {
      onStartUserSession(result.data);
    } else {
      setShowNotFound(true);
    }
  };

  const getListTenant = async () => {
    const result = await customerAPi.getTenantList();
    setListUserSelect(result?.data || []);
  };

  const isShowCreateUserProfileModal = () => {
    history.push({
      search: "",
    });
    if (userSelectType === "investor") {
      dispatch(reqSetIsShowCreateInvestorProfileModal(true));
      dispatch(reqSetIsShowListInvestorModal(false));
    } else if (userSelectType === "tenant") {
      dispatch(reqSetIsShowCreateTenantProfileModal(true));
      dispatch(reqSetIsShowListTenantModal(false));
    } else if (userSelectType === "consultant") {
      dispatch(reqSetIsShowCreateConsultantProfileModal(true));
      dispatch(reqSetIsShowListConsultantModal(false));
    }
  };

  const handleClose = () => {
    dispatch(reqSetIsShowListInvestorModal(false));
    dispatch(reqSetIsShowListTenantModal(false));
    dispatch(reqSetIsShowListConsultantModal(false));
  };

  const getActiveClass = (user) => {
    return selectedUser?.id === user?.id
      ? "cursor-pointer active"
      : "cursor-pointer";
  };

  const onStartUserSession = (user) => {
    history.push({
      search: `?customer=${user?.id}`,
    });
    setSelectedUser(user);
    dispatch(reqSetIsShowListInvestorModal(false));
    dispatch(reqSetIsShowListTenantModal(false));
    dispatch(reqSetIsShowListConsultantModal(false));

    analytics.track("Start Session", {
      customerType: userSelectType,
      customerFirstName: user.firstname,
      customerLastName: user.surname,
      customerEmail: user.email,
    });

    return startDiscover();
  };

  const handleCancel = () => {
    handleClose();
    dispatch(reqSetIsShowUserTypeModal(true));
  };

  const handleSubmitUser = async () => {
    if (isShowListInvestorModal) {
      await getListInvestor();
    }
    if (isShowListConsultantModal) {
      await getListConsultant();
    }
  };

  return (
    <>
      <Modal
        className="wrap-list-user-modal"
        show={
          isShowListTenantModal ||
          isShowListInvestorModal ||
          isShowListConsultantModal
        }
        onHide={handleClose}
        centered
      >
        <Modal.Body className="wrap-modal-body">
          <div className="close-btn">
            <img src={closeIcon} alt="close-icon" onClick={handleClose} />
          </div>
          <div className="modal-form__title">
            {t("ListUserSelectModal.title")}
          </div>
          <div className="mb-4 modal-form__sub-title">
            {t("ListUserSelectModal.subTitle")}
            <button onClick={isShowCreateUserProfileModal}>
              {t("ListUserSelectModal.create")}
            </button>
          </div>
          <div className="wrapper-list-user">
            <div className="first-last-name">
              <div className="form-info">
                <label htmlFor="email">{t("ListUserSelectModal.fn")}</label>
                <input
                  autoCapitalize="none"
                  onChange={(e) => setFirstName(e.target.value)}
                  id="firsName"
                  placeholder={t("ListUserSelectModal.fn")}
                />
              </div>
              <div className="form-info">
                <label htmlFor="email">{t("ListUserSelectModal.ln")}</label>
                <input
                  autoCapitalize="none"
                  onChange={(e) => setLastName(e.target.value)}
                  id="lastName"
                  placeholder={t("ListUserSelectModal.ln")}
                />
              </div>
            </div>
            <div className="form-info w-100 mt-4">
              <label htmlFor="email">{t("ListUserSelectModal.Email")}</label>
              <input
                autoCapitalize="none"
                onChange={(e) => setEmailAddress(e.target.value)}
                id="email"
                placeholder={t("ListUserSelectModal.Email")}
              />
            </div>
          </div>

          <div className="wrap-button-list-user d-flex justify-content-between">
            <button onClick={handleSubmitUser}>{t("common.Submit")}</button>
            <button onClick={handleCancel}>{t("common.Cancel")}</button>
          </div>
        </Modal.Body>
      </Modal>
      <ErrorModal
        isShow={isShowNotFound}
        onClose={() => setShowNotFound(false)}
      />
    </>
  );
};

export default ListUserSelectModal;
