import React from 'react';
import { Provider } from 'react-redux';
import { Router } from './routers/Router';
import store from './reduxs/store';
import './App.scss';
import {
  fetch3DSettings,
  fetchAssetsList,
  fetchHotspotsList,
  fetchPagesSettings,
} from './reduxs/scene/action';
import { ToastContainer, toast } from 'react-toastify';
import './i18n/i18n'

store.dispatch(fetch3DSettings());
store.dispatch(fetchAssetsList());
store.dispatch(fetchHotspotsList());
store.dispatch(fetchPagesSettings());

const App = () => (
  <Provider store={store}>
    <Router />
    <ToastContainer pauseOnFocusLoss={false}/>
  </Provider>
);

export default App;
