import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatMoney } from "../../../helper/unit";

const Row = (props) => {
  const {t} = useTranslation();
  const { data, onClickDetail } = props;
  const { name, level, availabilityStatus, totalLot, bedrooms, aspect, price, serviceCharge, description } = data;
  const [show, setShow] = useState(false)

  const renderAspect = (value) => {
    if(value === "sea_view") return "Sea View";
    if(value === "city_view") return "City View";
    return value
  }

  return (
    <tr
      style={{
        display: "table",
        width: "100%",
        tableLayout: "fixed",
      }} 
    >
      <td className="col-1" style={{borderBottom: "none"}}>
        <img
          onClick={() => {setShow(!show)}}
          src={`/icons/arrow-${show? "up": "down"}.svg`}
          alt=""
          className="arrow"
        /> 
      </td>
      <td className="col-3">
        {t("Residence")} {name}
      </td>
      <td className="col-2">
          {level}
      </td>
      <td className="col-4 text-capitalize">
          {t(availabilityStatus) || availabilityStatus} 
      </td>
      <td className="col-4 text-capitalize">
          {totalLot} 
      </td>
      <td className="col-2">
          {bedrooms} 
      </td>
      <td className="col-3">{t(renderAspect(aspect)) || renderAspect(aspect)}</td>
      <td className="col-3">{formatMoney(price)}</td>
      <td className="col-3">{formatMoney(serviceCharge)}</td>
      <td className="col-3">
        {show ? description : "..."}
      </td>
      <td className="col-1" style={{verticalAlign: show ? "top" : "middle"}}>
        <div className="actionButton" onClick={onClickDetail}>
        <img
          src={`/icons/dots.svg`}
          alt=""
        /> 
        </div>
      </td>
    </tr>
  );
}

export default Row;
