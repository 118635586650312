import { get } from "lodash"
import * as yup from 'yup';

export const parseResidenceData = (data) => {
  return {
    id: get(data, "id"),
    name: get(data, "name"),
    level: get(data, "level"),
    availabilityStatus: get(data, "availabilityStatus"),
    totalLot: get(data, "totalLot"),
    bedrooms: get(data, "bedrooms"),
    aspect: get(data, "aspect"),
    price: get(data, "price"),
    serviceCharge: get(data, "serviceCharge"),
    description: get(data, "description"),
  }
}

export const validationResidenceSchema = yup.object().shape({
  name: yup.string().trim().required("Name is a required field"),
  level: yup.string().trim().required("Level is a required field"),
  availabilityStatus: yup.string().trim().required("Availability is a required field"),
  totalLot: yup.string().trim().required("Lot Size is a required field"),
  bedrooms: yup.string().trim().required("Bedroom is a required field"),
  aspect: yup.string().trim().required("Aspect is a required field"),
  price: yup.string().trim().required("Price is a required field"),
  serviceCharge: yup.string().trim().required("Service charge is a required field"),
  description: yup.string().trim().required("Description cannot be empty"),
});
