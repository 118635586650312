import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import TopNavCMS from "./cms-top-nav";
import CMSCustomers from "./customers";
import CMSContent from './contents';
import CMSFrequentlyAQS from "./faq";
import "./index.scss";
import CMSResidence from "./residence";
import CMSAmenities from "./amenities";
import CMSAnalytics from "./analytics";
import CMSProfile from "./profile";
import { useDispatch, useSelector } from "react-redux";
import { reqGetUserProfile } from "../../reduxs/user/action";

export const cmsItems = [
  {
    position: 'left',
    path: "/cms/residence",
    navTitle: 'common.RESIDENCES',
    component: <CMSResidence />,
    roles: ['admin', 'agent'],
  },
  {
    position: 'left',
    path: "/cms/amenities",
    navTitle: 'AMENITIES',
    component: <CMSAmenities />,
    roles: ['admin'],
  },
  {
    position: 'left',
    path: "/cms/customers",
    navTitle: 'common.CUSTOMERS',
    component: <CMSCustomers />,
    roles: ['admin', 'agent'],
  },
  {
    position: 'right',
    path: "/cms/content",
    navTitle: 'common.CONTENT',
    component: <CMSContent />,
    roles: ['admin'],
  },
  {
    path: "/cms/analytics",
    navTitle: '',
    component: <CMSAnalytics />,
    ref: element => element && element.style.setProperty('width', '98px', 'important'),
    roles: ['admin'],
  },
  {
    path: "/cms/profile",
    navTitle: '',
    component: <CMSProfile />,
    ref: element => element && element.style.setProperty('width', '74px', 'important'),
    roles: ['admin'],
  },
  {
    position: 'right',
    path: "/cms/frequently-asked-questions",
    navTitle: 'FAQS',
    component: <CMSFrequentlyAQS />,
    divClassName: 'faq',
    ref: element => element && element.style.setProperty('width', '43px', 'important'),
    roles: ['admin', 'agent'],
  },
]

const CMS = (props) => {
  const { roles, authMiddleware } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.user.data);

  useEffect(() => {
    authMiddleware(roles, history);
    dispatch(reqGetUserProfile());
  }, []);

  return (
    <div className="wrap-cms-page">
      <TopNavCMS />
      <Switch>
        <Route
          exact
          path="/cms"
          render={() => {
            return <Redirect to="/cms/residence" />;
          }}
        />
        {cmsItems.map((item, i) => {
          if (!item.roles.includes(authUser?.userGroup?.name))
            return null;
          return (
            <Route key={i} path={item.path}>
              {item.component}
            </Route>
          );
        })}
      </Switch>
    </div>
  );
};
export default CMS;
